import React from "react";
import { Link } from "gatsby-plugin-intl";
import { graphql } from "gatsby";

import { CircledText, ButtonLink, Headline2Sans } from "@userbrain/website-ui";
import { concatClassNames as cn } from "@sys42/utils";
import Layout from "../layouts/layout";
import { HeaderTitle } from "../components/HeaderTitle";
import { WavySeparator } from "../components/WavySeparator";
import { GetStartedBox } from "../components/GetStartedBox";
import { Testimonials } from "../components/Testimonials";
import { FilteredPostsGrid } from "../components/FilteredPostsGrid";
import { TrustBox } from "../components/TrustBox";
import { Gallery } from "../components/Gallery";

import {
  testimonialPascal,
  testimonialJamie,
  testimonialPaul,
  testimonialThomas,
} from "../helpers";

import * as styles from "./styles/customers.module.css";
import * as helperStyles from "./helpers.module.css";

export default function Customers(props) {
  const {
    data: {
      allStrapiCustomerSuccessStory: { nodes: stories },
    },
  } = props;

  const heroElement = (
    <>
      <HeaderTitle overline={"Customer Success Stories"} titleWidth={"10em"}>
        <span className={helperStyles.h1Serif}>From</span> Insights{" "}
        <span className={helperStyles.h1Serif}>
          to <CircledText>Impact</CircledText>{" "}
        </span>{" "}
        <span className={helperStyles.h1Serif}>with Userbrain</span>
      </HeaderTitle>
      <Gallery
        className={styles.gallery}
        items={parseApiStoriesToGalleryItems(stories)}
      />
      <WavySeparator />
    </>
  );

  const allStoriesElement = (
    <section className={cn(helperStyles.container, styles.allStories)}>
      <Headline2Sans
        className={styles.sectionHeading}
        style={{ maxWidth: "10em" }}
      >
        All Customer Stories
      </Headline2Sans>
      <FilteredPostsGrid
        filtersHeading={"Filter by Industry"}
        posts={parseApiStoriesToFilteredPosts(stories)}
        className={styles.filteredPostsGrid}
      />
    </section>
  );

  const testimonialsElement = (
    <section className={cn(helperStyles.container, helperStyles.spacingLg)}>
      <Headline2Sans
        className={styles.sectionHeading}
        style={{ maxWidth: "10em" }}
      >
        What our customers are saying
      </Headline2Sans>
      <Testimonials
        testimonials={[
          testimonialThomas,
          testimonialPascal,
          testimonialPaul,
          testimonialJamie,
        ]}
      />
    </section>
  );

  return (
    <Layout notTranslated={true} activeMenuItem="resources/customers">
      {heroElement}
      {allStoriesElement}
      <div className={cn(helperStyles.container, helperStyles.spacingLg)}>
        <TrustBox
          className={styles.trustBox}
          title="Whether you’re a UX designer, researcher, or manager, with Userbrain you’re bound to build products people love to use."
        />
      </div>
      {testimonialsElement}
      <div className={cn(helperStyles.container, helperStyles.spacingLg)}>
        <GetStartedBox
          title={
            <>
              User testing, <strong>made simple.</strong>
            </>
          }
        />
      </div>
    </Layout>
  );
}

function parseApiStoriesToFilteredPosts(stories) {
  return stories.map((story) => ({
    primaryImage: story.image.localFile.childImageSharp?.gatsbyImageData,
    primaryImageAlt: `${story.companyName}'s product screenshot`,
    secondaryImage:
      story.companyLogo.localFile.childImageSharp?.gatsbyImageData,
    secondaryImageAlt: `${story.companyName} logo`,
    title: story.title,
    url: story.url,
    body: story.person?.name,
    caption: story.person?.title,
    filterValue: story.companyIndustry.toLowerCase(),
  }));
}

function parseApiStoriesToGalleryItems(stories) {
  return stories.map((story) => ({
    primaryImage: story.image.localFile.childImageSharp?.gatsbyImageData,
    primaryImageAlt: `${story.companyName}'s product screenshot`,
    secondaryImage:
      story.person?.image.localFile.childImageSharp?.gatsbyImageData,
    secondaryImageAlt: `${story.companyName} representative`,
    uid: story.companyName,
    linkTo: `/customers/${story.url}`,
    children: (
      <StoryContent
        name={story.person?.name}
        title={story.person?.title}
        companyName={story.companyName}
        linkTo={`/customers/${story.url}`}
      >
        {story.personFeaturedQuote}
      </StoryContent>
    ),
  }));
}

function StoryContent({ name, title, children, linkTo }) {
  return (
    <>
      <div className={styles.storyContentHeading}>{name}</div>
      <div className={styles.storyContentSubheading}>{title}</div>
      <div className={styles.storyContentQuote}>{children}</div>
      <ButtonLink
        className={styles.storyContentLink}
        customTag={Link}
        to={linkTo}
      >
        Read the story
      </ButtonLink>
    </>
  );
}

export const query = graphql`
  query customerSuccessStoriesOverview {
    allStrapiCustomerSuccessStory {
      nodes {
        person {
          title
          name
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 252
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        title
        url
        personFeaturedQuote
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1700
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        companyName
        companyIndustry
        companyLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 144
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
