import React, { useState, useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@sys42/utils";

import * as styles from "./styles.module.css";
import SvgIconCaretRight from "./icon-caret-right.svg";

/**
 * @param {Array<Item<Object>>} items
 * @property {string} Item.primaryImage
 * @property {string}  Item.primaryImageAlt
 * @property {string} Item.secondaryImage
 * @property {string}  Item.secondaryImageAlt
 * @property {string}  Item.uid
 * @property {string}  Item.linkTo
 * @property {ReactNode}  Item.children
 * @param {string} className
 */
export function Gallery({ items, className }) {
  const itemCount = items.length;
  const isWindowResizing = useIsWindowResizing();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const refCarousel = useRef(null);

  const goNext = () => {
    setActiveItemIndex((currentIndex) =>
      currentIndex === itemCount - 1 ? 0 : currentIndex + 1
    );
  };

  const goPrev = () => {
    setActiveItemIndex((currentIndex) =>
      currentIndex === 0 ? itemCount - 1 : currentIndex - 1
    );
  };

  useKeyboardPrevNext(refCarousel, goPrev, goNext);

  const primaryImages = items.map(
    ({ primaryImage, primaryImageAlt, uid, linkTo }, index) => (
      <Link
        key={uid}
        className={cn(
          styles.primaryImage,
          index === activeItemIndex && styles.primaryImage_active
        )}
        aria-label={`Item ${index} of ${items.length}`}
        aria-roledescription="item"
        onClick={(e) => {
          if (index === activeItemIndex) {
            return;
          } else {
            e.preventDefault();
            if (index > activeItemIndex) {
              goNext();
            } else if (index < activeItemIndex) {
              goPrev();
            }
          }
        }}
        to={linkTo}
      >
        <GatsbyImage image={primaryImage} alt={primaryImageAlt} />
      </Link>
    )
  );

  const secondaryImages = items.map(
    ({ secondaryImage, secondaryImageAlt, uid }, index) => (
      <GatsbyImage
        key={uid}
        image={secondaryImage}
        alt={secondaryImageAlt}
        className={cn(
          styles.secondaryImage,
          index === activeItemIndex && styles.secondaryImage_active
        )}
      />
    )
  );

  const childrenStack = (
    <div className={styles.childrenStack}>
      {items.map(({ children, uid }, index) => (
        <div
          className={cn(
            styles.children,
            index === activeItemIndex && styles.children_active
          )}
          key={uid}
        >
          {children}
        </div>
      ))}
    </div>
  );

  const paginationButtons = items.map(({ uid }, index) => (
    <button
      key={uid}
      role="tab"
      aria-label={`Go to item ${index + 1}`}
      title={`Go to item ${index + 1}`}
      aria-selected={index === activeItemIndex}
      className={cn(
        styles.paginationButton,
        index === activeItemIndex && styles.paginationButton_active
      )}
      onClick={() => setActiveItemIndex(index)}
    />
  ));

  return (
    <div
      ref={refCarousel}
      aria-roledescription="carousel"
      className={cn(styles.gallery, className)}
      style={{
        "--animation-duration": isWindowResizing ? "0s" : undefined,
        "--item-count": itemCount,
        "--item-active-index": activeItemIndex,
      }}
      // Not sure this is the best thing for accessibility but required
      // for component wide keyboard navigation @useKeyboardPrevNext.
      // Alternative is to attach it to the navigation or pagination buttons -
      // or to remove it altogether.
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex="0"
    >
      <div
        role="group"
        aria-label="Items Scroller"
        aria-live="polite"
        className={styles.scroller}
      >
        <div className={styles.primaryImages}>{primaryImages}</div>
        <div className={styles.content}>
          <div className={styles.secondaryImageStack}>{secondaryImages}</div>
          <div className={styles.navigation}>
            <NavigationButton onClick={goPrev} />
            <NavigationButton isNext onClick={goNext} />
          </div>
          {childrenStack}
        </div>
      </div>
      <div role="tablist" className={styles.pagination}>
        {paginationButtons}
      </div>
    </div>
  );
}

function NavigationButton({ onClick, isNext }) {
  return (
    <button
      title={`Go to ${isNext ? "next" : "previous"} item`}
      onClick={onClick}
      className={cn(
        styles.navigationButton,
        isNext && styles.navigationButton_next
      )}
    >
      <img
        aria-hidden
        src={SvgIconCaretRight}
        alt={isNext ? "Caret right" : "Caret left"}
      />
    </button>
  );
}

function useKeyboardPrevNext(ref, onGoPrev, onGoNext) {
  useEffect(() => {
    const elem = ref.current;

    if (elem) {
      function handleKeyDown(event) {
        switch (event.key) {
          case "ArrowLeft":
            onGoPrev();
            break;
          case "ArrowRight":
            onGoNext();
            break;
          default:
            break;
        }
      }
      elem.addEventListener("keydown", handleKeyDown);
      return () => {
        elem.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [onGoNext, onGoPrev, ref]);

  return ref;
}

function useIsWindowResizing() {
  const timeoutRef = useRef();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsActive(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsActive(false);
      }, 100);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isActive, timeoutRef]);

  return isActive;
}
